<template>
  <div>
    <vca-column>
      <vca-card class="shadowed">
        <vca-field :label="$t('deposit.add.info')">
          <h3>{{ $t("deposit.add.crew") }}</h3>
          <div v-if="!hasSystemPermission()">
            {{ user.crew.name }}
          </div>
          <div v-else>
            <vca-dropdown
              ref="crew"
              v-model="crewsList"
              :options="crewList"
              :title="$t('taking.form.crew.title')"
              :rules="$v.create.crew_id"
              label=""
              :errorMsg="$t('taking.form.crew.errorMsg')"
              :placeholder="$t('taking.form.crew.placeholder')"
            />
          </div>
        </vca-field>
        <vca-field :label="$t('deposit.add.takings')">
          <p>
            {{ $t("deposit.add.description") }}
          </p>
          <br />
          <vca-row
            class="deposit-lists"
            :key="index"
            v-for="(res, index) in create.deposit_units"
          >
            <IconButton
              type="cancel"
              @click="remove(index)"
              :title="$t('button.remove')"
            />
            <div class="vertical-center">{{ res.taking.name }}</div>
            <div class="margin-auto">
              <vca-money-input
                v-model="res.money"
                ref="amount"
                :currency="currencies"
                :select="true"
                css="small-money-input"
                :errorMsg="$t('taking.form.source.amount.errorMsg')"
                :toptext="$t('taking.form.source.amount.topText')"
              />
            </div>
            <div class="vertical-center"></div>
          </vca-row>
        </vca-field>

        <vca-field :label="$t('deposit.external.header')">
          <vca-checkbox v-model="create.has_external">
            {{ $t("deposit.add.has_external") }}</vca-checkbox
          >
          <DepositExternal
            v-if="create.has_external"
            v-model="create.external"
          />
          <vca-card v-if="create.has_external">
            {{ $t("deposit.external.info.text_add") }}
            {{ $t("deposit.external.info.text") }}
          </vca-card>
        </vca-field>
        <div v-if="create.deposit_units.length">
          <button class="vca-button" @click.prevent="add()">
            {{ $t("button.save") }}
          </button>
          <div class="vca-center">
            <vca-cancel-button
              @click="close()"
              :placeholder="$t('button.clear')"
            />
          </div>
        </div>
      </vca-card>
      <vca-card class="shadowed">
        <DepositAddTable v-model="create" />
      </vca-card>
    </vca-column>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import IconButton from "@/components/utils/IconButton";
import DepositAddTable from "./DepositAddTable.vue";
import DepositExternal from "./DepositExternal.vue";
export default {
  name: "DepositAdd",
  components: {
    DepositAddTable,
    DepositExternal,
    IconButton,
  },
  data() {
    return {
      submitView: false,
      hasExternal: false,
    };
  },
  computed: {
    crewsList: {
      set(value) {
        if (value.length > 0) {
          if (this.create.crew_id != value[0].value) {
            this.create.crew_id = value[0].value;
            this.resetDepositUnits();
          } else {
            this.create.crew_id = value[0].value;
          }
        } else {
          this.create.crew_id = null;
        }
      },
      get() {
        return !this.create.crew_id ? [] : [{ value: this.create.crew_id }];
      },
    },
    create: {
      set(value) {
        this.$store.commit("deposits/create", value);
      },
      get() {
        return this.$store.state.deposits.create;
      },
    },
    ...mapGetters({
      user: "user/current",
      crewList: "crews/dropdown",
      validation: "deposits/validations",
      hasSystemPermission: "user/roles/hasSystemPermission",
      currencies: "currencies",
    }),
  },
  validations() {
    return this.validation;
  },
  methods: {
    remove(index) {
      this.create.deposit_units.splice(index, 1);
    },
    close() {
      this.$emit("close");
    },
    add() {
      if (this.$v.create.$invalid) {
        this.$refs.crew.validate();
        return false;
      }
      this.$store.dispatch({ type: "deposits/create" }).then(() => {
        this.$emit("submit");
      });
    },
    formatMoney(value) {
      return value.amount / 100 + " €";
    },
    setCurrent(value) {
      if (this.current && this.current.id == value.id) {
        this.$store.commit("takings/current", null);
      } else {
        this.$store.commit("takings/current", value);
      }
    },
    resetDepositUnits() {
      this.create.deposit_units = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.deposit-lists {
  margin-top: 5px;
}
</style>
