<template>
  <div>
    <div class="img-row">
      <div
        v-for="(res, index) in value"
        :key="res.id"
        class="upload-img vca-card shadowed"
      >
        <div class="img">
          <img
            :src="receiptURL(res.id)"
            @click.prevent="download(res.id, index)"
          />
        </div>
        <div class="options">
          <vca-row>
            <div class="vca-center">
              <button
                class="vca-button"
                style="margin: 0; padding: 0"
                @click.prevent="download(res.id, index)"
              >
                {{ $t("button.download_icon") }}
              </button>
            </div>
            <div class="vca-center" v-if="displayDelete">
              <vca-cancel-button
                @click="deleteReceipt(res.id)"
                :placeholder="$t('button.delete')"
              />
            </div>
          </vca-row>
        </div>
      </div>
      <ReceiptUpload v-if="edit" :id="id" @updated="reload"></ReceiptUpload>
    </div>
    <button
      class="vca-button"
      style="width: auto"
      @click.prevent="downloadZip()"
    >
      {{ $t("button.download") }}
    </button>
  </div>
</template>
<script>
import ReceiptUpload from "@/components/finance/deposits/ReceiptUpload.vue";

export default {
  name: "ReceiptDisplay",
  components: { ReceiptUpload },
  props: {
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
    edit: {
      type: Boolean,
      default: false,
    },
    displayDelete: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: "quittung",
    },
    id: {
      type: String,
      default: "",
    },
  },
  methods: {
    receiptURL(value) {
      return process.env.VUE_APP_BACKEND_URL + "/finances/receipt/" + value;
    },
    reload() {
      this.$store
        .dispatch({ type: "deposits/getByID", data: this.id })
        .then(() => {
          this.$emit("updated");
        });
    },
    receiptURLZip() {
      return (
        process.env.VUE_APP_BACKEND_URL + "/finances/receipt/zip/" + this.id
      );
    },
    async download(value, index) {
      const image = await fetch(this.receiptURL(value), {
        credentials: "include",
      });
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);

      const link = document.createElement("a");
      link.href = imageURL;
      let number = index + 1;
      link.download = this.name + "_" + number;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async downloadZip() {
      const image = await fetch(this.receiptURLZip(), {
        credentials: "include",
      });
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);

      const link = document.createElement("a");
      link.href = imageURL;
      link.download = this.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    deleteReceipt(value) {
      this.$store
        .dispatch({
          type: "deposits/deleteReceipt",
          data: value,
        })
        .then(() => {
          this.$emit("updated");
        });
    },
  },
};
</script>

<style lang="scss">
.img-row {
  display: flex;
  gap: 1em;
}

.upload-img {
  width: 220px;
  height: 350px;

  .img {
    display: grid;
    place-items: center;
    height: 300px;
  }

  img {
    width: auto;
    max-height: 300px;
    max-width: 220px;
    height: auto;
  }
  img:hover {
    cursor: pointer;
  }

  .options {
    margin-top: auto;
    padding: 5px;
  }
}
</style>
